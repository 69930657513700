<template>
  <div class="warp">
    <div class="image-warp">
      <img src="@/assets/images/aboutus02.png" alt="" />
      <div class="image-introduction">
        国康中健是一家专业从事医疗行业资深的系统开发集成的公司,目前公司独立研发了互联网医院系统，已广泛应用于各大医疗机构
      </div>
    </div>

    <div class="location-section">
      <div class="section-tip"></div>
      <h3>澄迈（总公司）</h3>
    </div>
    <div class="location-info">
      <p>地址：海南省澄迈县老城镇高新技术产业示范区海南生态软件园孵化楼</p>
      <p>电话：<a href="tel:13373117291">13373117291</a>（微信与电话同号）</p>
      <p>邮编：571924</p>
    </div>

    <div class="location-section">
      <div class="section-tip"></div>
      <h3>成都（子公司）</h3>
    </div>
    <div class="location-info">
      <p>地址：成都市双流区国芯大道399号 5 栋第 8 层 5-802 室</p>
      <p>电话：<a href="tel:13373117291">13373117291</a>（微信与电话同号）</p>
      <p>邮编：610000</p>
    </div>

    <div class="location-section">
      <div class="section-tip"></div>
      <h3>北京（子公司）</h3>
    </div>
    <div class="location-info">
      <p>地址：北京市海淀区信息路28号一栋八层b座801B07室</p>
      <p>电话：<a href="tel:13373117291">13373117291</a>（微信与电话同号）</p>
      <p>邮编：100000</p>
    </div>

    <!-- <div class="location-section">
      <div class="section-tip"></div>
      <h3>天津（子公司）</h3>
    </div>
    <div class="location-info">
      <p>地址：天津市</p>
      <p>电话：<a href="tel:13373117291">13373117291</a>（微信与电话同号）</p>
      <p>邮编：300000</p>
    </div> -->

  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style lang="less" scoped>
.warp {
  width: 100%;
  margin-top: 25px;
}
.image-warp {
  width: 315px;
  height: 171px;
  position: relative;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .image-introduction {
    width: 94%;
    position: absolute;
    bottom: 12px;
    left: 3%;
    font-size: 10px;
    font-weight: 400;
    color: #022e77;
  }
}
.location-section {
  width: 315px;
  margin: 15px auto 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .section-tip {
    background-color: #045DF0;
    width: 5px;
    height: 10px;
    border-radius: 2.5px;
    margin-right: 4px;
  }
}
.location-info {
  width: 315px;
  margin: 0px auto 0;
  p {
    font-size: 10px;
    font-weight: 400;
    color: #022e77;
    padding-top: 11px;
  }
}
</style>
